<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- Table -->
            <form class="needs-validation" @submit.prevent="Form">
              <div class="row justify-content-md-center mt-4">
                <div class="col-md-12">
                  <div class="row align-items-center">
                    <div class="col-sm-12 col-md-12 col-12">
                      <div v-for="(data, index) in rowsData" :key="index">
                        <!-- <p><b>วันเวลาที่นำเข้า:</b> {{ data.createdAt }}</p>
                        <p><b>โดย:</b> {{ data.createdBy }}</p>
                        <b>สถานะ: </b>
                        <p class="badge bg-success" v-if="data.isSuccess === 1">
                          สำเร็จ
                        </p> -->
                        <b-alert
                          v-if="data.isSuccess === 0"
                          variant="danger"
                          show
                          class="text-center mt-4 px-4 mb-0"
                        >
                          <i
                            class="uil uil-exclamation-octagon d-block display-4 mt-2 mb-3 text-danger"
                          ></i>
                          <h5 class="text-danger">ไม่สำเร็จ</h5>
                          <p><b>วันเวลาที่นำเข้า:</b> {{ data.createdAt }}</p>
                          <p><b>โดย:</b> {{ data.createdBy }}</p>
                          <h5 class="text-danger">Error Code</h5>
                          <p>{{ data.errMsg }}</p>
                          <div>
                            <a :href="data.path" class="btn btn-success"
                              >ดาวน์โหลด</a
                            >
                          </div>
                        </b-alert>
                        <b-alert
                          v-else
                          variant="success"
                          show
                          class="text-center mt-4 px-4 mb-0"
                        >
                          <i
                            class="uil uil-check-circle d-block display-4 mt-2 mb-3 text-success"
                          ></i>
                          <h5 class="text-success">สำเร็จ</h5>
                          <p><b>วันเวลาที่นำเข้า:</b> {{ data.createdAt }}</p>
                          <p><b>โดย:</b> {{ data.createdBy }}</p>
                          <div>
                            <a :href="data.path" class="btn btn-success"
                              >ดาวน์โหลด</a
                            >
                          </div>
                          <!-- <p>{{ data.errMsg }}</p> -->
                        </b-alert>
                        <!-- <p class="badge bg-danger" v-if="data.isSuccess === 0">
                          ไม่สำเร็จ
                        </p> -->
                        <br />
                        <!-- <div>
                          <a :href="data.path" class="btn btn-success"
                            >ดาวน์โหลด</a
                          >
                        </div> -->
                      </div>
                    </div>
                    <!-- <div class="row float-end"></div> -->
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import useNetw from "@useNetw";
import {
  required,

  // url,
  // alphaNum,
} from "vuelidate/lib/validators";
// import branchComponent from "@/components/branchComponent.vue";

export default {
  page: {
    title: appConfig.impSale,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader },

  data() {
    return {
      loading: undefined,
      error: "กรุณาตรวจสอบข้อมูลอีกครั้ง.",
      title: appConfig.notification,
      items: [
        {
          text: "นำเข้าข้อมูล",
          active: true,
        },
        {
          text: appConfig.notification,
          active: true,
        },
      ],
      // partId: this.$route.params.partId,
      model: [],
      totalPage: "",
      branchId: [],
      selectMode: "multi",
      selected: [],
      isLoading: "",
      id: "",
      page: "",
      to: "",
      total: "",
      from: "",
      tooltipform: { files: null },

      totalRecord: "",
      rowData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      salaryLabel: "",
      rowsData: [],
    };
  },
  validations: {
    tooltipform: {
      files: {
        required,
      },
    },
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.rowParts.length;
    },
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
    });

    this.localDataBranchIdUser = arrayBranch;
    this.branchId = user.branchId;
    this.localData = user.userAccessBranch;
    this.getData();
    // Set the initial number of items
  },
  created() {
    // this.getLocalData();
  },
  methods: {
    getData() {
      this.loading = true;

      useNetw
        .get(`api/dealer-import-sales/show/${this.$route.params.notiId}`)
        .then((response) => {
          this.rowsData = response.data;
          // if (response.data.data.isSuccess === 0) {
          //   Swal.fire(
          //     appConfig.swal.title.error,
          //     JSON.stringify(response.data.data.errMsg),
          //     appConfig.swal.type.error
          //   );
          // }
          console.log(response);
          //   Swal.fire(
          //     appConfig.swal.title.postSuccess,
          //     JSON.stringify(response.data.message),
          //     appConfig.swal.type.success
          //   );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
          // this.loading = false;
        })
        .then(() => {
          this.loading = false;
        });
    },
    alert(rowData) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการลบหรือหรือไม่ ?",
          icon: "warning",
          confirmButtonText: "ลบ",
          cancelButtonText: "ยกเลิก",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.deleteParts(rowData);
          }
        });
    },
  },
  middleware: "authentication",
};
</script>
